import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
  <header className="bg-blue-500 p-4 text-white text-center">
    <h1>Adam Zalewski's E-Portfolio</h1>
    <nav>
      <Link to="/about" className="mr-4">About</Link>
      <Link to="/events">Events</Link>
    </nav>
  </header>
);

export default Header;