import React from "react";
import { useParams } from "react-router-dom";
import QuantumComputer from "../assets/quantum_computer.jpg";
import LectureBruges from "../assets/lecture_bruges.jpg";
import Inuits from "../assets/inuits.jpg";
import Hackathon from "../assets/hackathon.jpg";
import Meet from "../assets/meet.jpg";
import ReactTailwind from "../assets/reactandtailwind.webp";

const eventDetails = {
  "quantum-computing": {
    title: "Masterclass Quantum Computing",
    type: "Passive Event",
    summary:
      "A detailed lecture on the advancements and future of Quantum Computing.",
    description: (
      <div>
        <p className="mb-4">
          Hosted by Team Howest Cluster Computer Science in collaboration with
          IBM Quantum Ambassadors, the event promised an immersive dive into the
          realm of quantum computing. As I entered the brand new Building of
          Howest, anticipation filled the air, mingled with the excitement of
          fellow quantum enthusiasts gathered for the occasion.
        </p>
        <p className="mb-4">
          The event kicked off with an invigorating session delving into the
          fundamentals of quantum computing. Led by experts in the field, we
          explored the intricate concepts of quantum algorithms, qubits, and the
          potential impact of quantum computing on various industries. The
          session was not merely informative but also thought-provoking,
          igniting a spark of curiosity within each attendee.
        </p>
        <p className="mb-4">
          The Masterclass Quantum Computing event was more than just a learning
          experience; it was a transformative journey that opened doors to new
          possibilities and ignited a passion for quantum exploration. As I
          reflect on the insights gained and the connections made, I am reminded
          of the boundless potential that quantum computing holds for shaping
          the future of technology and beyond.
        </p>
      </div>
    ),
    image: QuantumComputer,
  },
  "lecture-bruges": {
    title: "Lecture at Sint-Jozefsinstituut",
    type: "Public Relations Event",
    summary:
      "Delivered an interactive lecture on Git and software architecture to high school students in Bruges",
    description: (
      <div>
        <p className="mb-4">
          We, alongside my fellow colleagues, had the privilege of conducting a
          comprehensive lecture aimed at enlightening the 6th-grade students of
          the technical high school, Sint-Jozefsinstituut in Bruges. Our focus
          was on elucidating the intricate concepts of Git and the essential
          principles of front-end and back-end separation architecture. This
          educational endeavor was specifically tailored for the Computer
          Science students of the institution.
        </p>
        <p className="mb-4">
          Drawing from our own experiences as former students of
          Sint-Jozefsinstituut, we recognized the significance of imparting this
          knowledge in a familiar setting. It was a nostalgic journey as we
          revisited the very classrooms where we once sat, under the tutelage of
          our esteemed teacher, Lander Viaene.
        </p>
        <p className="mb-4">
          Over the course of two sessions, each spanning two hours, we engaged
          with two distinct classes, comprising approximately 20 students each.
          Following the lectures, we provided practical exercises, fostering
          hands-on learning experiences for the attendees.
        </p>
        <p className="mb-4">
          Furthermore, we were honored by the presence of our former teacher,
          Dimitri Casier, who graciously took the time to attend our sessions,
          offering invaluable feedback and insights from his wealth of
          experience.
        </p>
      </div>
    ),
    image: LectureBruges,
  },
  "open-source-cafe": {
    title: "Open Source Cafe",
    type: "Extra Event",
    summary:
      "Explored the realms of software monitoring and delved into the intricacies of Tesla AI systems, all while relishing in complimentary refreshments of free drinks and pizza.",
    description: (
      <div>
        <p className="mb-4">
          Embarked on an enlightening journey at the Ghent library, graciously
          hosted by the forward-thinking team at Inuits. As the pioneers of
          DevOps, Inuits provided a captivating exploration into software
          monitoring and Tesla's groundbreaking AI systems. The evening
          commenced with an insightful session elucidating the nuances between
          monitoring and observability, while delving into essential concepts
          such as logs, traces, and metrics.
        </p>
        <p className="mb-4">
          Transitioning seamlessly, we ventured into the realm of Tesla's AI
          prowess, gaining invaluable insights into the innovative technologies
          propelling their vehicles into the future. Amidst the enriching
          discussions, we indulged in complimentary refreshments, enjoying the
          camaraderie and exchanging ideas with fellow enthusiasts. To top it
          off, we were gifted with exclusive memorabilia, including
          company-branded pens and bags, serving as tangible reminders of an
          inspiring evening of learning and discovery.
        </p>
      </div>
    ),
    image: Inuits,
  },
  hackathon: {
    title: "Decentralized Autonomous Hackathon",
    type: "Active Event",
    Summary:
      "Participated in the Decentralized Autonomous Hackathon 2024 organized by Cyber3Lab.",
    description: (
      <div>
        <p className="mb-4">
          Immersed in the thrilling atmosphere of the Decentralized Autonomous
          Hackathon 2024, I embarked on a captivating journey at the forefront
          of blockchain innovation. Hosted by Cyber3Lab, this hackathon
          challenged participants to conceive and develop projects leveraging
          the power of blockchain technology.
        </p>
        <p className="mb-4">
          Guided by the hackathon's theme, my team and I embarked on an
          exhilarating journey to create a project that seamlessly integrated
          blockchain technology. Our vision culminated in the development of a
          unique concept: a crypto arcade machine. Inspired by the
          transformative potential of blockchain, our project aimed to
          revolutionize the gaming industry by introducing decentralized
          elements into the arcade experience.
        </p>
        <p className="mb-4">
          As the hackathon unfolded, we poured our passion and expertise into
          bringing our project to life. Fueled by creativity and determination,
          our efforts culminated in a remarkable achievement – securing the
          third place in the competition. This recognition not only validated
          our hard work but also affirmed our commitment to innovation in the
          blockchain space.
        </p>
        <p className="mb-4">
          One of the unique aspects of the hackathon was the opportunity to
          fully immerse oneself in the experience. With the option to stay and
          sleep on-site, participants embraced the spirit of innovation around
          the clock, forging connections, and pushing the boundaries of
          blockchain technology.
        </p>
        <p className="mb-4">
          The Decentralized Autonomous Hackathon 2024 was more than just a
          competition – it was a journey of discovery, collaboration, and
          innovation. As I reflect on the experience, I am filled with gratitude
          for the opportunity to contribute to the blockchain ecosystem and to
          be part of a community dedicated to shaping the future of technology.
        </p>
      </div>
    ),
    image: Hackathon,
  },
  flutter: {
    title: "Tech Meet & Mix Event",
    type: "Passive Event",
    Summary: "A comprehensive session on the use of Flutter and Redwood.js",
    description: (
      <div>
        <p className="mb-4">
          At the Tech Meet & Mix event, I had the opportunity to delve into two
          compelling presentations by industry experts Fré Dumazy and Adam
          Juran. Fré's talk centered on Flutter, Google's UI toolkit, where he
          highlighted its practical advantages and how it's revolutionizing app
          development. His insights shed light on Flutter's intuitive design and
          productivity-enhancing features, leaving me intrigued by its potential
          applications.
        </p>
        <p className="mb-4">
          Adam's presentation focused on RedwoodJS, a comprehensive full-stack
          framework designed to streamline web development. As he walked us
          through its key features, including GraphQL, TypeScript, and React
          integration, I gained a deeper understanding of its capabilities and
          how it's reshaping the development landscape. Adam's firsthand
          experience and expertise, especially coming straight from California,
          added a valuable perspective to the discussion.
        </p>
        <p className="mb-4">
          Beyond the presentations, the event provided a platform for networking
          and engaging with fellow enthusiasts in the tech community.
          Conversations sparked around shared interests and experiences,
          offering valuable insights and connections that I look forward to
          exploring further.
        </p>
        <p className="mb-4">
          Overall, the Tech Meet & Mix event was an enriching experience that
          broadened my understanding of emerging technologies and provided
          opportunities for professional growth and networking within the
          industry.
        </p>
      </div>
    ),
    image: Meet,
  },
  technologies: {
    title: "Technologies Used",
    type: "List of Technologies",
    summary: "Find out how I built this website.",
    description: (
      <div>
        <p className="mb-4">
          To create this e-portfolio, I used React along with Tailwind CSS. The
          combination of React for building the frontend components and Tailwind
          CSS for styling provided a smooth and efficient development
          experience.
        </p>
        <p className="mb-4">
          After building the website, I deployed it on a Combell server to make
          it accessible online. Combell's reliable hosting services ensure that
          my portfolio is always available for visitors to explore.
        </p>
      </div>
    ),
    image: ReactTailwind,
  },
  // Add other events here with unique IDs
};

const EventDetails = () => {
  const { id } = useParams();
  const event = eventDetails[id];

  if (!event) {
    return <div>Event not found</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md animate-fadeIn">
      <img
        src={event.image}
        alt={event.title}
        className="w-full h-128 object-cover rounded-lg mb-4"
      />
      <h2 className="text-3xl font-bold mb-4">{event.title}</h2>
      <p className="text-sm italic mb-2 text-gray-600">{event.type}</p>
      <p className="text-gray-600 mb-4 italic">{event.summary}</p>
      <p className="text-gray-700">{event.description}</p>
    </div>
  );
};

export default EventDetails;
