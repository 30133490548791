import React from 'react';
import { Link } from 'react-router-dom';

const Event = ({ id, title, type, summary, image }) => (
  <Link to={`/event/${id}`}>
  <div className="max-w-md mx-auto p-6 border border-gray-300 rounded-lg shadow-lg mb-6 bg-white transform hover:scale-105 transition-transform duration-300 animate-fadeIn">
    <div className="w-full h-48 mb-4 overflow-hidden">
      <img
        src={image}
        alt={title}
        className="w-full h-full object-cover rounded-t-lg"
        style={{ objectFit: 'contain' }}
      />
    </div>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p className="text-sm italic mb-2 text-gray-600">{type}</p>
    <p className="text-gray-700">{summary}</p>
  </div>
  </Link>
);

export default Event;