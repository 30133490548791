import React from "react";
import Event from "../components/event";
import quantumComputing from "../assets/quantum_computing.png";
import flutterRedwoodjs from "../assets/flutter_redwoodjs.png";
import lectureBruges from "../assets/lecture_bruges.svg";
import openSourceCafe from "../assets/open_source_cafe.svg";
import hackaton from "../assets/cyber3lab.svg";
import Website from "../assets/website.jpg"

const EventsPage = () => {
  const passiveEvents = [
    {
      id: "quantum-computing",
      title: "Masterclass Quantum Computing",
      type: "Passive Event",
      summary:
        "An insightful lecture on the basics and advancements in quantum computing.",
      image: quantumComputing,
    },
    {
      id: "flutter",
      title: "Tech Meet & Mix Event",
      type: "Passive Event",
      summary:
        "A comprehensive session on the use of Flutter for mobile development and Redwood.js for full-stack web development.",
      image: flutterRedwoodjs,
    },
  ];

  const activeEvent = {
    id: "hackathon",
    title: "Decentralized Autonomous Hackathon",
    type: "Active Event",
    summary:
      "Participated in the Decentralized Autonomous Hackathon 2024 organized by Cyber3Lab. It was a great experience focusing on decentralized technologies.",
    image: hackaton,
  };

  const prEvent = {
    id: "lecture-bruges",
    title: "Lecture at Sint-Jozefsinstituut",
    type: "Public Relations Event",
    summary:
      "Conducted a lecture with my colleagues on Git and front-end/back-end architecture to high school students in Bruges.",
    image: lectureBruges,
  };

  const extraEvent = {
    id: "open-source-cafe",
    title: "Open Source Cafe",
    type: "Extra Event",
    summary:
      "Explored the realms of software monitoring and delved into the intricacies of Tesla AI systems, all while relishing in complimentary refreshments of free drinks and pizza.",
    image: openSourceCafe,
  };

  const technologies = {
    id: "technologies",
    title: "Technologies Used",
    type: "List of Technologies",
    summary: "Find out how I built this website.",
    image: Website
  }

  const events = [...passiveEvents, activeEvent, prEvent, extraEvent, technologies];

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4 text-center">Events</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {events.map((event, index) => (
          <Event key={index} {...event} />
        ))}
      </div>
    </div>
  );
};

export default EventsPage;
