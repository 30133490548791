import React from 'react';
import adamZalewski from '../assets/adam_zalewski.jpg';

const About = () => (
  <div className="p-4 flex justify-center animate-fadeIn">
    <div className="max-w-lg bg-white p-6 rounded-lg shadow-md">
      <img src={adamZalewski} alt="Adam Zalewski" className="w-64 h-64 rounded-full mx-auto mb-4" />
      <h2 className="text-2xl font-bold mb-4 text-center">About Me</h2>
      <p className="mb-4 text-center">
        Hello, I'm Adam Zalewski, a 22-year-old final year student in Applied Computer Science, specializing in Software Engineering.
        Currently, I'm working as a software developer at Securex Belgium.
      </p>
      <p className="mb-4 text-center">
        This website serves as my e-portfolio, showcasing my academic and professional journey, as well as my participation in various events.
        Through this portfolio, I aim to highlight my skills, experiences, and the projects I have worked on.
      </p>
      <p className="text-center">
        Feel free to explore the events section to see the various lectures and sessions I have attended and participated in, and learn more about my contributions and experiences in the field of software development.
      </p>
    </div>
  </div>
);

export default About;