import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import AboutPage from './pages/aboutPage';
import EventsPage from './pages/eventsPage';
import EventDetails from './pages/eventDetails';

const App = () => (
  <Router>
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow p-4">
        <Routes>
          <Route path="/" element={<AboutPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/event/:id" Component={EventDetails} />
        </Routes>
      </main>
      <Footer />
    </div>
  </Router>
);

export default App;